<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">合笼终点</h3>
    <div class="detail">
      <el-tabs v-model="tabsValue" class="tabsCage" @tab-click="change">
        <el-tab-pane
            :key="index"
            v-for="(item, index) in tabs"
            :label="item.tit"
            :name="item.name"
        >
          <el-table
              :data="tableDate" border
              class="tableMember" :span-method="objectSpanMethod"
              :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          >
            <el-table-column label="笼舍" prop="cageNo" show-overflow-tooltip></el-table-column>
            <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
            <el-table-column label="性别">
              <template slot-scope="scope">{{ scope.row.gender == 1 ? '♂' : '♀' }}</template>
            </el-table-column>
            <el-table-column label="备注" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-input size="small" placeholder="备注" :readonly="readonly" v-model="scope.row.remark"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="是否确认取出动物" class-name="btnClass" width="140">
              <template slot-scope="scope">
                <el-button size="mini" @click="takeOut(scope.row)" :disabled="disabled">确认</el-button>
              </template>
            </el-table-column>
            <el-table-column label="是否确认结束使用笼舍" class-name="btnClass" width="170">
              <template slot-scope="scope">
                <el-button size="mini" @click="endUse(scope.row)" :disabled="disabled">确认</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="fromSave">
        <el-button type="primary" size="small" :disabled="disabled" @click="recordEnd(1)">记录完成</el-button>
        <el-button type="primary" plain size="small" :disabled="disabled" @click="recordEnd(0)">保存</el-button>
      </div>
    </div>
    <!--    结束使用笼舍-->
    <el-dialog :show-close="false" :close-on-click-modal="false"
               class="commonDialog flex" center
               top="0" :close-on-press-escape="false"
               :visible.sync="endUseDia"
               width="260px">
      <img src="../../../../assets/img/tips.png" alt="" width="52px">
      <h3>是否确认结束该笼舍？</h3>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain @click="endUseDia=false">取消</el-button>
        <el-button size="small" @click="submitUse">确认</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "end",
  props: ['id', 'isLabAni'],
  inject: ['reload'],
  data() {
    return {
      tabsValue: 'first',
      tabs: [{tit: "父本母本", name: 'first'}, {tit: "新生动物", name: 'second'}],
      tableDate: [],
      endUseDia: false,
      userDia: {
        id: null,
        aniFinishList: []
      },
      disabled: false,
      readonly: false
    }
  },
  mounted() {
    if (this.tabsValue == 'first') {
      this.getParent()
    } else {
      this.getChildren()
    }
  },
  methods: {
    change(val) {
      if (val.name == 'first') {
        this.getParent()
      } else {
        this.getChildren()
      }
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0 || columnIndex === 5) {
        if (row.row) {
          return {
            rowspan: row.row,
            colspan: 1
          };
        }
        return {
          rowspan: 0,
          colspan: 0
        };
      }
    },
    // 获取父本母本
    getParent() {
      this.$get("/mate/aniCage/finish/" + this.id, {isLabAni: this.isLabAni}).then(res => {
        if (res.status == 200) {
          let arr = [];
          res.data.forEach(item => {
            if (item.state == 1) {
              this.readonly = true
              this.disabled = true
            }
            item.mateAniCageList.forEach(obj => {
              // 笼舍赋值
              obj.cageNo = item.number
              // 合并单元格判断
              if (item.mateAniCageList.length > 1) {
                this.$set(item.mateAniCageList[0], 'row', item.mateAniCageList.length)
              } else {
                this.$set(obj, 'row', item.mateAniCageList.length)
              }
              arr.push(obj)
            })
          })
          this.tableDate = arr
        }
      }).catch(error => {
        console.log(error);
      })
    },
    // 获取新生动物
    getChildren() {
      this.$get("/mate/baby/finish/" + this.id).then(res => {
        if (res.status == 200) {
          let arr = [];
          res.data.forEach(item => {
            if (item.state == 1) {
              this.readonly = true
              this.disabled = true
            }
            item.mateBabyList.forEach(obj => {
              // 合并单元格判断
              if (item.mateBabyList.length > 1) {
                this.$set(item.mateBabyList[0], 'row', item.mateBabyList.length)
              } else {
                this.$set(obj, 'row', item.mateBabyList.length)
              }
              arr.push(obj)
            })
          })
          this.tableDate = arr
        }
      }).catch(error => {
        console.log(error);
      })
    },
    // 取出小鼠 确认备注
    takeOut(row) {
      console.log(row)
      let url;
      if (this.tabsValue == 'first') {
        // 父本母本
        row.isDel = 1
        url = this.$putJson('/mate/aniCage', row)
      } else {
        // 新生小鼠
        row.state = 3
        url = this.$putJson('/mate/baby', row)
      }
      url.then(res => {
        if (res.status == 200) {
          this.$message.success("取出小鼠成功")
          this.reload();
        }
      }).catch(err => {return false;
      })
    },
    // 结束使用笼舍
    endUse(row) {
      // type=0 父母本 type=1 新生小鼠
      if (this.tabsValue == 'first') {
        row.type = 0
      } else {
        row.type = 1
      }
      this.userDia.id = row.cageId
      this.userDia.aniFinishList.push({
        aniId: row.id,
        remark: row.remark,
        type: row.type
      })
      this.endUseDia = true
    },
    submitUse() {
      this.$putJson('/subject/cage/' + this.userDia.id, this.userDia.aniFinishList).then(res => {
        if (res.status == 200) {
          this.$message.success("结束使用笼舍成功")
          this.endUseDia = false
          this.reload();
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 记录完成
    recordEnd(state) {
      this.$put('/subject/mate/finish/' + this.id, {state: state}).then(res => {
        if (res.status == 200) {
          this.$message.success("提交成功")
          this.$router.go(-1)
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.tabsCage {
  margin-left: 0;
}
</style>
